import { useDispatch } from 'react-redux';
import { useOwnerOptions } from '../../hooks/useOwnerOptions';
import { LegalRegisterModel } from '../../swagger/models/LegalRegisterModel';
import { LegalRegisterUpdatePayload } from '../../swagger/models/LegalRegisterUpdatePayload';
import { PestelRegisterModel } from '../../swagger/models/PestelRegisterModel';
import React, { useState } from 'react';
import { Lozenge } from '../Lozenge/Lozenge';
import { AdoptechReactSelect } from '../AdoptechReactSelect/AdoptechReactSelect';
import { AdoptechDatePicker } from '../AdoptechDatePicker/AdoptechDatePicker';
import moment from 'moment';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import { Dropdown } from 'react-bootstrap';
import { AdoptechDropdownToggle } from '../AdoptechDropdownToggle/AdoptechDropdownToggle';
import { push } from 'connected-react-router';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import './RegistryHeader.scss';
import { CalendarHS } from '../../features/calendar/CalendarHS/CalendarHS';
import { AdoptechPanel } from '../AdoptechPanel/AdoptechPanel';
import { PestelItemItemType } from '../../swagger/models/PestelItemItemType';
import {
  reportsRouteWithAddType,
  reportsRouteWithType,
} from '../Reports/ReportsPage/ReportsPage';
import { canFeature } from '../../functions/access';
import { AccessObject } from '../../types/accessObject';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import { components } from 'react-select';
import classNames from 'classnames';
import { ReviewStatusEnum } from '../../swagger/models/ReviewStatusEnum';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { RegisterReviewDrawer } from '../RegisterReviewDrawer/RegisterReviewDrawer';
import { formatShortDate } from '../../functions/formatShortDate';
import { registerTitle } from '../../functions/registerTitle';

export interface RegistryHeaderProps {
  setShowCreateAction: React.Dispatch<React.SetStateAction<boolean>>;
  setIsShowingAddDrawer: React.Dispatch<
    React.SetStateAction<boolean | `${PestelItemItemType}`>
  >;
  isLoadingList: boolean;
  canManageRegister: boolean;
  register: LegalRegisterModel | PestelRegisterModel;
  onChange: (arg0: LegalRegisterUpdatePayload) => void;
  registerType: 'LegalRegister' | 'PestelRegister';
  // For apply sticky header's we must have one container for all of them with solid white background
  // else we would have background glitches between header and search bar + would need to specify top values
  additionalHeaderRows: any;
}

export const RegistryHeader: React.FC<RegistryHeaderProps> = ({
  setShowCreateAction,
  setIsShowingAddDrawer,
  isLoadingList,
  canManageRegister,
  register,
  onChange,
  registerType,
  additionalHeaderRows,
}) => {
  const baseCss = 'registryHeader';
  const dispatch = useDispatch();
  const [showCalendarHS, setShowCalendarHS] = useState(false);
  const canManageReports = canFeature(AccessObject.reports_manage);
  const canViewReports = canFeature(AccessObject.reports);
  const [showRegisterReviewDrawer, setShowRegisterReviewDrawer] =
    useState(false);
  const { ownerOptions } = useOwnerOptions({
    onlyAdminable: true,
  });

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon
          className={baseCss + '--angleIcon'}
          icon={faAngleDown}
        />
      </components.DropdownIndicator>
    );
  };

  const isLegal = registerType === 'LegalRegister';
  const reviewStatus = register.reviewStatus;

  return (
    <div className="sticky-header">
      <AdoptechPanel className={baseCss + '--header'}>
        <>
          <div className={baseCss + '--statusColumn'}>
            <div className={baseCss + '--columnLabel'}>Status</div>
            {reviewStatus && (
              <div
                className={classNames(
                  `${baseCss}--review-status`,
                  `${baseCss}--review-status-${reviewStatus
                    .toLowerCase()
                    .split(' ')
                    .join('-')
                    .replace('_', '-')}`
                )}
              >
                <FontAwesomeIcon
                  icon={
                    reviewStatus === ReviewStatusEnum.Reviewed
                      ? faCheckCircle
                      : faExclamationCircle
                  }
                />
                {reviewStatus === ReviewStatusEnum.Reviewed
                  ? 'Reviewed'
                  : 'Awaiting Review'}
              </div>
            )}
          </div>
          <div className={baseCss + '--divider'} />
          <div className={baseCss + '--lastReviewed'}>
            <div className={baseCss + '--columnLabel'}>Last Reviewed</div>
            <div className={baseCss + '--lastReviewedText'}>
              {formatShortDate(register.lastReviewedAt) || '-'}
            </div>
          </div>
          <div className={baseCss + '--divider'} />
        </>
        <div className={baseCss + '--reviewDateColumn'}>
          <div className={baseCss + '--columnLabel'}>Next Review</div>
          <div className={baseCss + '--nextReviewText'}>
            {formatShortDate(register?.nextReviewDate) || '-'}
          </div>
        </div>
        <div className={baseCss + '--divider'} />

        <div className={baseCss + '--ownerColumn'}>
          <div className={baseCss + '--columnLabel'}>Register Owner</div>
          <AdoptechReactSelect
            label={null}
            id="owner-filter"
            options={ownerOptions}
            onChange={option => onChange({ ownerId: option.value })}
            value={ownerOptions.find(
              option => option.value === register.owner?.id
            )}
            placeholder="Select owner"
            showUserAvatar
            showUserEmail={false}
            components={{ DropdownIndicator }}
            isDisabled={!canManageRegister}
          />
        </div>
        <div className={baseCss + '--divider'} />
        <div className={baseCss + '--actionsRaisedColumn'}>
          <div className={baseCss + '--columnLabel'}>Actions</div>
          <div className={baseCss + '--actionsRaisedRow'}>
            <Lozenge value={register?.stats?.actionsCount} />
            <AdoptechButton
              variant={AdoptechButtonVariant.White}
              extraClass="adoptechButton-smallRounded"
              onClick={() => setShowCalendarHS(true)}
            >
              View
            </AdoptechButton>
          </div>
        </div>
        <div className={baseCss + '--divider'} />
        {!isLoadingList && (
          <div className={baseCss + '--headerButtons'}>
            {canViewReports && (
              <Dropdown className={baseCss + '--reportsButton'}>
                <Dropdown.Toggle as={AdoptechDropdownToggle}>
                  <AdoptechButton
                    variant={AdoptechButtonVariant.Default}
                    icon={faEye}
                    iconSize="large"
                    rounded
                    extraClass={`${baseCss}--reports-btn`}
                  >
                    Reports
                  </AdoptechButton>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {canManageReports && (
                    <Dropdown.Item
                      onClick={() =>
                        dispatch(
                          push(
                            reportsRouteWithAddType(
                              registerType === 'PestelRegister'
                                ? 'pestel'
                                : 'legal'
                            )
                          )
                        )
                      }
                    >
                      Add report
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    onClick={() =>
                      dispatch(
                        push(
                          reportsRouteWithType(
                            registerType === 'PestelRegister'
                              ? 'pestel'
                              : 'legal'
                          )
                        )
                      )
                    }
                  >
                    View reports
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
            {canManageRegister && (
              <Dropdown className={baseCss + '--addButton'}>
                <Dropdown.Toggle as={AdoptechDropdownToggle}>
                  <AdoptechButton
                    variant={AdoptechButtonVariant.PrimaryOrange}
                    icon={faPlus}
                    iconSize="large"
                    rounded
                  >
                    Add new
                  </AdoptechButton>
                </Dropdown.Toggle>
                {registerType === 'PestelRegister' && (
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => setIsShowingAddDrawer('risk')}
                    >
                      Risk
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => setIsShowingAddDrawer('opportunity')}
                    >
                      Opportunity
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setShowCreateAction(true);
                      }}
                    >
                      Action
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setShowRegisterReviewDrawer(true);
                      }}
                    >
                      Register Review
                    </Dropdown.Item>
                  </Dropdown.Menu>
                )}
                {registerType === 'LegalRegister' && (
                  <>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => setIsShowingAddDrawer(true)}
                      >
                        Legislation
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setShowCreateAction(true);
                        }}
                      >
                        Action
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setShowRegisterReviewDrawer(true);
                        }}
                      >
                        Register Review
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </>
                )}
              </Dropdown>
            )}
          </div>
        )}

        {showCalendarHS && (
          <CalendarHS
            onClose={() => setShowCalendarHS(false)}
            title={`Actions raised for ${registerTitle(registerType)}`}
            registerId={register?.id}
            registerType={registerType}
          />
        )}

        {showRegisterReviewDrawer && (
          <RegisterReviewDrawer
            onClose={() => setShowRegisterReviewDrawer(false)}
            register={register}
            registerType={registerType}
          />
        )}
      </AdoptechPanel>
      {additionalHeaderRows}
    </div>
  );
};
